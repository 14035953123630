<template>
  <div style="background-color: #fff;width: 100%;">
    <div class="header flexal">
      <img src="/pc_static/img/logo.png" alt="" style="width: 165px;height: 80px;margin-right: 24px;" />
      <div class="flexcum flexspb" style="height: 70%;">
        <h1>欢迎注册</h1>
        <div class="deng">
          <router-link to="/login">我已注册，马上登录></router-link>
        </div>
      </div>
    </div>
    <div>
      <div class="m-fnbox ui_panel" style="display: block;">
        <div class="fnlogin clearfix">
          <div class="line">
            <label class="linel"><em>*</em><span class="dt">用户名：</span></label>
            <div class="liner">
              <input type="text" class="inp fmobile J_cellphone" placeholder="请输入手机号或者邮箱：" name="username" id="username"
                required="" v-model="name" />
              <p class="fn-fl errorbox v-txt" id="err_username">
                手机号码输入不正确
              </p>
            </div>
          </div>

          <div class="line">
            <label class="linel"><em>*</em><span class="dt">设置密码：</span></label>
            <div class="liner">
              <input type="password" id="password" class="inp fpass J_password" placeholder="6-16位大小写英文字母、数字或符号的组合"
                autocomplete="off" maxlength="16" value="" required="" v-model="pass" />
              <input name="password" value="" type="hidden" />
              <p class="fn-fl noticebox v-txt2" id="err_password">
                6-16位字符，建议由字母、数字和符号两种以上组合
              </p>
            </div>
          </div>
          <div class="line">
            <label class="linel"><em>*</em><span class="dt">确认密码：</span></label>
            <div class="liner">
              <input type="password" id="password2" class="inp fsecpass J_password2" placeholder="请再次输入密码"
                autocomplete="off" maxlength="16" required="" value="" v-model="confirmPass" />
              <input name="password2" value="" type="hidden" />
              <p class="fn-fl errorbox v-txt" id="err_password2">
                两次输入密码不一致
              </p>
            </div>
          </div>
          <div class="line liney clearfix">
            <label class="linel">&nbsp;</label>
            <div class="liner" style="padding-left: 160px;">
              <div class="clearfix checkcon">
                <p class="fn-fl checktxt">
                  <input type="checkbox" class="iyes fn-fl J_protocal" checked v-model="checked" />
                  <span class="fn-fl">我已阅读并同意</span><a class="itxt fn-fl"
                    href="/Home/Article/agreement/doc_code/agreement.html" target="_blank">《服务条款》</a>
                  <a class="itxt fn-fl" href="/Home/Article/agreement/doc_code/privacy.html" target="_blank">《隐私条款》</a>
                </p>
                <p class="fn-fl errorbox v-txt" id="protocalBox"></p>
              </div>
              <a id="submit" class="regbtn J_btn_agree" href="javascript:void(0);"
              v-on:click="Register">同意协议并注册</a>
              <p class="v-txt" id="err_check_code">
                <span class="fnred">请勾选</span>我已阅读并同意<a class="itxt"
                  href="/Home/Article/agreement/doc_code/agreement.html" target="_blank">《服务条款》</a>
                <a class="itxt" href="/Home/Article/agreement/doc_code/privacy.html" target="_blank">《隐私条款》</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // 用户名的校验方法
    let validateName = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      if (userNameRule.test(value)) {
        //判断数据库中是否已经存在该用户名
        var res = await this.postdata("/api/Login/findUserName", {
          queryName: this.RegisterUser.name
        })
        if (res.code == 200) {
          this.$refs.ruleForm.validateField("checkPass");
          return callback();
        } else {
          return callback(new Error(res.msg));
        }
      } else {
        return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      }
      // 校验是否以密码一致
      if (this.pass != "" && value === this.pass) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    return {
      checked: false,
      isRegister: false, // 控制注册组件是否显示
      name: "",
      pass: "",
      confirmPass: "",
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{
          validator: validateName,
          trigger: "blur"
        }],
        pass: [{
          validator: validatePass,
          trigger: "blur"
        }],
        confirmPass: [{
          validator: validateConfirmPass,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    async Register() {
      if (!this.checked) {
        this.notifyError('请勾选隐私条款');
        return false
      }
	  const regEmail = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
	  const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
	  
	  if ( !regEmail.test(this.name) && !regMobile.test(this.name)) {
	  	this.notifyError('请输入正确的手机号或者邮箱');
	  	return
	  }
	  
      if(this.pass == "" || this.confirmPass  == "" ){
        this.notifyError('请输入密码');
        return 
      }
      if (this.confirmPass != this.pass) {
        this.notifyError('两次输入的密码不一致');
        return 
      }
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      // this.$refs["ruleForm"].validate(async valid => {
      //如果通过校验开始注册
      // if (valid) {
      var res = await this.postdata("/api/Login/RegisterUser", {
        UserName: this.name,
        password: this.pass
      })
      if (res.code == 200) {
        // 隐藏注册组件
        this.isRegister = false;
        // 弹出通知框提示注册成功信息
        this.notifySucceed(res.msg);
        this.$router.push({
        path: '/login',
      });
      } else {
        // 弹出通知框提示注册失败信息
        this.notifyError(res.msg);
      }
      
      // } else {
      // 	return false;
      // }
      // });
    }
  }
};
</script>
<style>
.header {
  width: 1240px;
  height: 93px;
  margin: 0 auto;
  background-color: #fff;
  padding-left: 60px;
}

.deng a {
  margin-top: 8px;
  color: rgb(0, 47, 255);
}

.m-fnbox {
  border: 1px solid #d5d5d5;
  background: #fff;
  padding: 35px;
  clear: both;
  margin-bottom: 50px
}

.fnlogin {
  font-family: 'Microsoft YaHei';
  font-size: 12px;
  color: #3c3c3c;
  /* padding-left:60px; */
  height: 305px;
  margin: 0 auto;
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.fnlogin input {
  margin: 0px;
  padding: 0px;
}

.fnlogin .ltit {
  color: #333;
  padding-bottom: 37px;
  font-size: 14px;
  font-weight: bold;
}

.fnlogin .inp {
  float: left;
  border: 1px solid #ccc;
  padding: 8px;
  height: 18px;
  line-height: 18px;
  margin-right: 10px;
  font-family: 'Microsoft YaHei';
  outline: 0 none;
  font-size: 14px;
  vertical-align: middle;
  width: 290px;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.fnlogin .fcolor {
  color: #3c3c3c;
}

.fnlogin .red {
  padding-left: 10px;
}

.fnlogin .nred {
  border: 1px solid #DA3A4C;
}

/* .fnlogin .success{
    background:url(../images/fn-icon.png) 280px -89px no-repeat;
}
.fnlogin .error{
    background:url(../images/fn-icon.png) 126px 10px no-repeat;
} */
.fnlogin .success.fcode,
.fnlogin .success.imgcode {
  background-position: 125px -89px;
}

.fnlogin .error.fmobile,
.fnlogin .error.femail,
.fnlogin .error.fpass,
.fnlogin .error.fsecpass {
  background-position: 280px 10px;
}

.fnlogin .noticebox {
  width: 227px;
  color: #DA3A4C;
  background: #fff8e1;
  border: 1px solid #fddbaa;
  height: 32px;
  line-height: 15px;
  padding: 2px 8px 0px 8px;
}

.fnlogin .noticebox2 {
  width: 227px;
  color: #DA3A4C;
  background: #fff8e1;
  border: 1px solid #fddbaa;
  height: 32px;
  line-height: 32px;
  padding: 2px 8px 0px 8px;
}

.fnlogin .errorbox {
  width: 300px;
  color: #DA3A4C;
  background: #ffebeb;
  border: 1px solid #ffbdbe;
  height: 18px;
  line-height: 18px;
  padding: 8px;
}

.fnlogin .v-captcha {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: left;
  margin-right: 10px;
  height: 34px;
  line-height: 34px;
}

.fnlogin .v-captcha img {
  display: inline;
  float: left;
  width: 85px;
  height: 34px;
  margin-right: 10px;
}

.fnlogin .v-captcha a {
  color: #0066cc;
  cursor: pointer;
}

.fnlogin .fcode,
.regcon .imgcode {
  width: 137px;
}

.fnlogin .icode {
  display: block;
  text-align: center;
  line-height: 35px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 1px;
  height: 35px;
  color: #3c3c3c;
  margin: 0 30px 0 5px;
  width: 115px;
  outline: 0 none;
  cursor: pointer;
  font-family: 'Microsoft YaHei';
  font-size: 12px;
}

.fnlogin .icode:hover {
  text-decoration: underline;
}

.fnlogin .disabled.icode {
  color: #ccc;
  cursor: default;
}

.fnlogin .disabled.icode:hover {
  text-decoration: none;
}

.fnlogin .line {
  height: 37px;
  padding-bottom: 25px;
}

.fnlogin .liney {
  height: auto;
  padding-bottom: 0px;
}

.fnlogin .line .dt {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  /* background:url(../images/fn-icon.png) 0px -137px no-repeat; */
}

.fnlogin .line .linel {
  float: left;
  text-align: right;
  width: 90px;

}

.fnlogin .line .liner {
  float: left;
}

.show-voice {
  width: 700px;
  line-height: 20px;
  font-size: 12px;
  color: #999;
  padding-left: 85px;
  float: left;
  display: none;
}

.fnlogin .v-txt,
.regcon .ui_panel,
.fnlogin .v-txt2 {
  display: none;
}

.fnlogin .safeline {
  clear: both;
  padding-top: 4px;
  padding-left: 49px;
  color: #666;
}

.fnlogin .safeline .sz {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  color: #fff;
  background: #d5d5d5;
  width: 65px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  margin-left: 1px;
}

.fnlogin .safeline .son {
  background: #DA3A4C;
}

.fnlogin .iyes {
  margin-right: 5px;
  margin-top: 2px;
}

.fnlogin .checkcon {
  height: 34px;
}

.fnlogin .checkcon .checktxt {
  height: 18px;
  padding-right: 106px;
  padding-top: 10px;
}

.fnlogin .itxt {
  color: #0066cc;
  text-decoration: none;
}

.fnlogin .itxt:hover {
  text-decoration: underline;
}

.fnlogin .regbtn {
  display: block;
  clear: both;
  cursor: pointer;
  margin: 20px 0px 10px 0;
  width: 250px;
  height: 40px;
  color: #fff;
  font-family: 'Microsoft YaHei';
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  background: #DA3A4C;
  text-decoration: none;
}

.fnlogin .regbtn:hover {
  background: #ce0c22;
}
</style>
